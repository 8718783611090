.App {
  text-align: center;
  max-width: 600px;
  margin: 20px auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}

.hidden {
  display: none;
}

.session-list{
  padding-top: 15px;
}

p {
  padding: 15px;
}

.wise-style {
  background: #CDCDCD;
  border-radius: 0.25em;
  justify-items: left;
  text-align: left;
}

.align-text-left {
  text-align: left;
}